import Repository from './repository'

const resource = '/usuario'

export default {

  getUsuarioId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList () {
    return Repository.get(`${resource}/getList`)
  },
  UpdateUsuario (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  DeleteUsuario (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  getDadosUsuarioSistema (data) {
    return Repository.get(`${resource}/parametros`, { params: data })
  },
}
