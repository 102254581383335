import Repository from './repository'

const resource = '/caixa'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList () {
    return Repository.get(`${resource}/getlist`)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}/save/`, data)
  },
  getDadosCaixa (data) {
    return Repository.get(`${resource}/getCaixa`, { params: data })
  },
}
