<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title v-if="editar">
          Editar Caixa *
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Novo Caixa *
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSave"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <componenteLoja
                v-if="carregarCombo"
                :id="caixadados.cdLoja"
                dense
                @onSelectLoja="onSelectLoja"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row v-if="!editar">
            <v-col
              cols="2"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="caixadados.cdCaixa"
                label="Código Caixa"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <componenteSetorVenda
                v-if="carregarCombo"
                :id="caixadados.cdSetorVenda"
                dense
                @onSetorVenda="onSetorVenda"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="7"
            >
              <v-text-field
                v-if="carregarCombo"
                v-model="caixadados.nmCaixa"
                label="Nome do Caixa"
                :rules="nmCaixaRules"
                :counter="40"
                required
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <componenteUsuario
                v-if="carregarCombo"
                :id="caixadados.cdUsuario"
                dense
                @onSelectUsuario="onSelectUsuario"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-select
                v-if="carregarCombo"
                v-model="caixadados.stTrabalho"
                :items="tipoTrabalho"
                item-text="descricao"
                item-value="codigo"
                label="Status"
                dense
                outlined
                hide-details
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>
<script>
  import caixaRep from './../../components/services/caixaRepository'
  import componenteLoja from './componente-loja'
  import componenteSetorVenda from './componente-setorvenda'
  import componenteUsuario from './componente-usuario'

  export default {
    components: {
      componenteLoja,
      componenteSetorVenda,
      componenteUsuario,
    },
    props: {
      editar: {
        type: Boolean,
        required: true,
      },
      id: {
        type: Number,
        default: 0,
        required: false,
      },
    },
    data () {
      return {
        caixadados: {
          cdcaixa: 0,
        },
        valid: false,
        nmCaixaRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 40 || 'Quantidade de Caracteres superior que o necessario',
        ],
        codigoUsuarioRules: [
          v => !!v || 'Name obrigatorio',
          v => v.length <= 15 || 'Quantidade de Caracteres superior que o necessario',
        ],
        tipoTrabalho: [{
          codigo: 'A',
          descricao: 'ATIVO',
        }, {
          codigo: 'D',
          descricao: 'DEMITIDO',
        }, {
          codigo: 'F',
          descricao: 'FÉRIAS',
        }],
        carregarCombo: false,
      }
    },
    async created () {
      await this.getList()
      // var dadosThis = this.caixadados
      setTimeout(() => {
        this.carregarCombo = true
      }, 1000)
    },
    methods: {
      onSair () {
        this.$emit('onSairCadastro')
      },
      async getList () {
        if (this.editar) {
          try {
            this.$store.commit('setSplashScreen', true)
            await caixaRep.getId(this.id).then(response => {
              if (response.data.status === 'sucesso') {
                this.caixadados = response.data.dados
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.dados,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          } catch (error) {

          }
        }
      },
      onSave () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setSplashScreen', true)
          if (this.editar === false) {
            this.caixadados.editar = false
            this.caixadados.cdCaixa = parseInt(this.caixadados.cdCaixa)
            caixaRep.Save(this.caixadados).then(response => {
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          } else {
            this.$store.commit('setSplashScreen', true)
            this.caixadados.editar = true
            this.caixadados.cdCaixa = parseInt(this.caixadados.cdCaixa)
            caixaRep.Save(this.caixadados).then(response => {
              // listaUsuarios = response.data
              if (response.data.status === 'sucesso') {
                this.$emit('onSucesso')
              } else if (response.data.status === 'mensagem') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'warning',
                  text: response.data.mensagem,
                })
              }
              this.$store.commit('setSplashScreen', false)
            }).catch(error => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: error,
              })
            })
          }
        }
      },
      onSelectLoja (cdLoja) {
        this.caixadados.cdloja = cdLoja
      },
      onSetorVenda (cdSetorVenda) {
        this.caixadados.cdSetorVenda = cdSetorVenda
      },
      onSelectUsuario (cdUsuario) {
        this.caixadados.cdUsuario = cdUsuario
      },
    },
  }
</script>
