<template>
  <v-select
    v-model="cdSetorVenda"
    :items="listaSetorVenda"
    item-text="nmSetorVenda"
    item-value="cdSetorVenda"
    label="Setor Venda"
    outlined
    :readonly="readonly"
    hide-details
    :dense="dense"
    @change="onSelectSetorVenda"
  />
</template>
<script>
  import setorVendaRep from './../../components/services/setorvendaRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
      cdloja: {
        type: Number,
        default: 1,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        listaSetorVenda: [],
        cdSetorVenda: 0,
      }
    },
    created () {
      this.getList()
      this.id === 0 ? this.cdSetorVenda = 0 : this.cdSetorVenda = this.id
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await setorVendaRep.getList(this.cdloja).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaSetorVenda = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSelectSetorVenda () {
        this.$emit('onSelectSetorVenda', this.cdSetorVenda)
      },
    },
  }
</script>
