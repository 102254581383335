<template>
  <v-select
    v-model="cdUsuario"
    :items="listaUsuario"
    item-text="cdUsuario"
    item-value="cdUsuario"
    label="Usuário"
    outlined
    hide-details
    :dense="dense"
    @change="onSelect"
  />
</template>
<script>
  import usuarioRep from './../../components/services/usuarioRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        listaUsuario: [],
        cdUsuario: '',
      }
    },
    created () {
      this.getList()
      this.id === '' ? this.cdUsuario = '' : this.cdUsuario = this.id.trim()
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await usuarioRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaUsuario = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSelect () {
        this.$emit('onSelectUsuario', this.cdUsuario)
      },
    },
  }
</script>
